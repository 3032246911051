@import "fonts/concourse.css";
@import "fonts/triplicate.css";

:root {
  --body-background: #fffff8;
  --body-color: hsl(0, 0%, 7%);
  --heading-color: hsl(0, 0%, 7%);
  --action-color: #d64045;
  --body-background-darker: #f7f7ef;
  --secret-project-color: hsl(0, 0%, 99%);
}

html {
  font-size: 20px;
}

body {
  width: 87.5%;
  background: var(--body-background);
  color: var(--body-color);
  font-family: "ConcourseT3", -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin-right: auto;
  padding-left: 8%;
  line-height: 1.5;
  max-width: 850px;
  counter-reset: sidenote-counter;
}

/* Adds dark mode */
/* @media (prefers-color-scheme: dark) {
  body {
    background-color: #151515;
    color: #ddd;
  }
} */

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  color: var(--heading-color);
  font-weight: 400;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  font-size: 3.2rem;
  line-height: 1;
}

h2 {
  font-style: italic;
  font-weight: 400;
  margin-top: 2.1rem;
  margin-bottom: 1.4rem;
  font-size: 2.2rem;
  line-height: 1;
}

h3 {
  font-style: italic;
  font-weight: 400;
  font-size: 1.7rem;
  margin-top: 2rem;
  margin-bottom: 1.4rem;
  line-height: 1;
}

h4,
h5,
h6 {
  margin-bottom: 0.5rem;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

p.subtitle {
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  display: block;
  line-height: 1;
}

p.summary {
  font-size: 1.25rem;
  line-height: 1.1;
  width: 85%;
  font-style: italic;
}

/*
 Top NAV Default styles
*/
body>header {
  margin: 1rem;
  text-align: center;
}

body>header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body>nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body>nav a {
  text-decoration: none;
}

/*
END OF DEFAULT NAV
*/

/*
 START OF RAM NAV
*/
.ram-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}



.ram-nav header h1 {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.ram-nav nav ul {
  font-size: 1rem;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}

/*
 END OF RAM NAV
*/

/* START OF RAM-LINK STYLES */
.ram-link * {
  color: var(--secret-project-color);
  text-decoration-color: var(--secret-project-color);
}

.ram-link h4 {
  margin-bottom: 0;
}

.ram-link p {
  margin-top: 0.1rem;
}


/* END OF RAM-LINK STYLES*/

main {
  /* margin: 2rem auto 4rem; */
  max-width: 52rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  padding: 5rem 0rem;

  /* width: 55%; */
  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

p,
dl,
ol,
ul {
  font-size: 1rem;
  line-height: 2rem;
}

ul ul {
  /* first level of nesting */
  padding-left: 20px;
}

ul ul ul {
  /* second level of nesting */
  padding-left: 15px;
}

ul ul ul ul {
  /* third level of nesting */
  padding-left: 10px;
  list-style-type: lower-latin;
}

ul ul ul ul ul {
  /* fourth level of nesting and beyond */
  padding-left: 5px;
  list-style-type: lower-roman;
}

p {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  padding-right: 0;
  vertical-align: baseline;
}



footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

.post-dates p {
  font-size: 80%;
  line-height: 1;
  margin: 0.5rem 0 0.5rem 0;

}



pre,
code {
  font-family: "triplicate_t4_code_regular", Menlo, Monaco, monospace;
  background-color: #dce1e6;
}

code {
  font-size: 0.8rem;
}

h2>code {
  font-size: 1.25rem;
}

h3>code {
  font-size: 1.25rem;
}

pre>code {
  font-size: 0.9rem;
  width: 90%;
  margin-left: 2.5%;
  overflow-x: auto;
  display: block;
  background-color: #F6F8FA;
}

pre.fullwidth>code {
  width: 90%;
}

.fullwidth {
  max-width: 90%;
  clear: both;
}

/* Block quotes */
blockquote {
  background-color: var(--body-background-darker);
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 1em 1em 1em 2em;

  margin: auto;

}


blockquote p {
  width: 80%;
  margin: 0px 0px;
  font-size: 85%;
}

blockquote footer {
  width: 55%;
  font-size: 1.1rem;
  text-align: right;
}

/* Chapter Epigraphs */
div.epigraph {
  margin: 1em 0;
  width: 34rem;
}

div.epigraph blockquote {
  margin: 0;
  border: none;
  background-color: var(--body-background);
  font-style: italic;
  width: 100%
}

div.epigraph blockquote,
div.epigraph blockquote p {
  font-size: 100%;
  width: 100%;
}

div.epigraph blockquote footer {
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: right;
  width: 100%;
}

div.epigraph blockquote footer cite {
  margin: 0;
}

/* end chapter epigraphs styles */

/* Margin Notes and Side Notes */
.sidenote,
.marginnote {
  float: right;
  clear: right;
  margin-right: -60%;
  width: 50%;
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  line-height: 1.3;
  vertical-align: baseline;
  position: relative;
}

input.margin-toggle {
  display: none;
}

label.margin-toggle:not(.sidenote-number) {
  display: none;
}

.sidenote-number {
  counter-increment: sidenote-counter;
}


.sidenote-number:after,
.sidenote:before {
  position: relative;
  vertical-align: baseline;
  font-size: 0.5rem;
}

.sidenote-number:after {
  content: counter(sidenote-counter);
  top: -0.5rem;
  left: 0.1rem;
}

.sidenote:before {
  content: counter(sidenote-counter) " ";
  top: -0.5rem;
}

blockquote .sidenote,
blockquote .marginnote {
  margin-right: -82%;
  min-width: 59%;
  text-align: left;
}

.sidenote>code,
.marginnote>code {
  font-size: 0.8rem
}

span.newthought {
  font-variant: small-caps;
  font-size: 1.2em;
  font-family: 'Concourse Small Caps';
}

.specification {
  border: #b0abab solid 1px;
  border-radius: 1rem;
  box-sizing: border-box;
  background-color: #F5F4F4;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}

.specification-body {
  padding: 10px;
  box-sizing: border-box;
}

.specification h3 {
  border-bottom: #b0abab solid 1px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 0px;
  margin-top: 0px;
}

.specification p {
  margin-bottom: 1rem;
  margin-top: 1rem;
}



/* Mobile styles */

@media (max-width: 760px) {
  html {
    font-size: 13px;
  }

  body {
    width: 84%;
    padding-left: 8%;
    padding-right: 8%;
  }

  hr,
  section>p,
  section>footer,
  section>table {
    width: 100%;
  }

  pre>code {
    width: 97%;
  }

  section>dl,
  section>ol,
  section>ul {
    width: 90%;
  }

  figure {
    max-width: 90%;
  }

  figcaption,
  figure.fullwidth figcaption {
    margin-right: 0%;
    max-width: none;
  }

  blockquote {
    margin-left: 1.5em;
    margin-right: 0em;
  }

  blockquote p,
  blockquote footer {
    width: 100%;
  }

  label.margin-toggle:not(.sidenote-number) {
    display: inline;
  }

  .sidenote,
  .marginnote {
    display: none;
  }

  .margin-toggle:checked+.sidenote,
  .margin-toggle:checked+.marginnote {
    display: block;
    float: left;
    left: 1rem;
    clear: both;
    width: 95%;
    margin: 1rem 2.5%;
    position: relative;
  }

  label {
    cursor: pointer;
  }

  div.table-wrapper,
  table {
    width: 85%;
  }

  img {
    width: 100%;
  }
}