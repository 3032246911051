/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 12, 2018 */

@font-face {
    font-family: 'ConcourseT3';
    src: url("fonts/concourse_t3_bold_italic-webfont.woff") format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'ConcourseT3';
    src: url("fonts/concourse_t3_bold-webfont.woff") format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: 'ConcourseT3';
    src: url("fonts/concourse_t3_italic-webfont.woff") format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}


@font-face {
    font-family: 'ConcourseT3';
    src: url("fonts/concourse_t3_regular-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Concourse Small Caps';
    src: url("fonts/concourse_c3_regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-variant: small-caps;
}