@font-face {
  font-family: 'triplicate_t4_code_bold';
  src: url("fonts/triplicate_t4_code_bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'triplicate_t4_code_bold_italic';
  src: url("fonts/triplicate_t4_code_bold_italic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}


@font-face {
  font-family: 'triplicate_t4_code_italic';
  src: url("fonts/triplicate_t4_code_italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}


@font-face {
  font-family: 'triplicate_t4_code_regular';
  src: url("fonts/triplicate_t4_code_regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}